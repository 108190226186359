@charset "utf-8";

// Vendor mixin/function libraries
@import "../bower_components/foundation-sites/scss/foundation";
@import "config/foundation";

// Currently all values are hardcoded, create your own variables...
body {
    font-family: Verdana,Geneva,sans-serif;
    font-weight: 300;
    min-height: 100%;
    font-size:14px;
    background:image-url('bck-lines.png');

}

#wrap {
    background-size: cover !important;
    width: 100%;
    margin:0 auto;
    height: 900px;
    padding:0;
    position: relative;
    .logo {
        position:absolute;
        top: 56px;
        left: 56px;
        width: 430px;
        height: 270px;
        text-indent:-9999px;
    }
    .error {
        margin:0 auto;
        width:400px;
      }
        .header {
            background-color: #CDDC39;
            color:#000;
            height: 90px;
            padding:12px 40px 20px;
            font-weight:400;
            .code {
                h1 {
                    font-size:42px;
                    text-transform: uppercase;
                    margin:10px 0 0;
                    font-weight:400;
                }
            }
            .message {
                font-size:26px;
                line-height:26px;
                //margin-top:20px;
                p {
                    margin:0;
                }
            }
        }
        .possibilities {
            padding:36px 40px;
            height:370px;
            position:relative;
            background:#fff;
            color:#000;
            h2 {
                font-size: 18px;
                margin-top:0;
                font-weight:400;
            }
        }
        .home-link {
            display: block;
            text-align: center;
            text-decoration: none;
            height:50px;
            width:370px;
            text-align:center;
            padding:24px 15px 5px;
            color: #fff;
            font-size:18px;
            background: #000;
            position: absolute;
            bottom:0;
            right:0;
            &:hover {
                background-color:#666;
            }
        }

}
